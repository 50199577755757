export type AskResponse = {
  answer: string
  citations: Citation[]
  error?: string
  message_id?: string
  feedback?: Feedback
}

export interface GroupedChatHistory {
  month: string
  entries: Conversation[]
}

export type ParsedAnswer = {
  citations: Citation[]
  markdownFormatText: string
}

export enum ChatHistoryLoadingState {
  Loading = 'loading',
  Success = 'success',
  Fail = 'fail',
  NotStarted = 'notStarted',
}

export const enum messageStatus {
  NotRunning = 'Not Running',
  Processing = 'Processing',
  Done = 'Done',
}

export type Citation = {
  part_index?: number
  content: string
  id: string
  title: string | null
  filepath: string | null
  url: string | null
  metadata: string | null
  chunk_id: string | null
  reindex_id: string | null
}

export const [USER, ASSISTANT, TOOL, ERROR] = [
  'user',
  'assistant',
  'tool',
  'error',
]
export const NO_CONTENT_ERROR = 'No content in messages object.'

export type ToolMessageContent = {
  citations: Citation[]
  intent: string
}

export type ChatMessage = {
  id: string
  role: string
  content: string
  end_turn?: boolean
  date: string
  feedback?: Feedback
  context?: string
}

export type Conversation = {
  id: string
  title: string
  messages: ChatMessage[]
  date: string
}

export enum ChatCompletionType {
  ChatCompletion = 'chat.completion',
  ChatCompletionChunk = 'chat.completion.chunk',
}

export type ChatResponseChoice = {
  messages: ChatMessage[]
}

export type ChatResponse = {
  id: string
  model: string
  created: number
  object: ChatCompletionType
  choices: ChatResponseChoice[]
  history_metadata: {
    conversation_id: string
    title: string
    date: string
  }
  error?: any
}

export type ConversationRequest = {
  messages: ChatMessage[]
}

export enum Feedback {
  Neutral = 'neutral',
  Positive = 'positive',
  Negative = 'negative',
  MissingCitation = 'missing_citation',
  WrongCitation = 'wrong_citation',
  OutOfScope = 'out_of_scope',
  InaccurateOrIrrelevant = 'inaccurate_or_irrelevant',
  OtherUnhelpful = 'other_unhelpful',
  HateSpeech = 'hate_speech',
  Violent = 'violent',
  Sexual = 'sexual',
  Manipulative = 'manipulative',
  OtherHarmful = 'other_harmful',
}
