
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'QuestionInput',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    conversationId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      question: '',
    }
  },
  computed: {
    sendQuestionDisabled() {
      return this.disabled || !this.question.trim()
    },
    sendButtonAriaLabel() {
      return this.$t('aiChat.sendButtonAriaLabel')
    },
  },
  methods: {
    sendQuestion() {
      if (this.disabled || !this.question.trim()) {
        return
      }

      if (this.conversationId) {
        this.onSend(this.question, this.conversationId)
      } else {
        this.onSend(this.question)
      }
      this.question = ''
    },
    onSend(question: string, conversationId?: string) {
      this.$emit('send', { question, conversationId })
    },
    onEnterPress(ev) {
      if (ev.key === 'Enter' && !ev.shiftKey && !(ev.isComposing === true)) {
        ev.preventDefault()
        this.sendQuestion()
      }
    },
  },
})
