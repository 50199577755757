
import { defineComponent } from 'vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import QuestionInput from './QuestionInput.vue'
import Answer from './Answer.vue'
import { uuid } from 'vue-uuid'
import {
  ChatMessage,
  ASSISTANT,
  TOOL,
  ERROR,
  NO_CONTENT_ERROR,
  ChatResponse,
  ToolMessageContent,
  messageStatus,
  Conversation,
  USER,
} from '@/constants/aiChatModels'
import { isEmpty } from 'lodash'
import ChatHistoryPanel from './ChatHistoryPanel.vue'

export default defineComponent({
  name: 'Chat',
  props: {
    isChatHistoryOpen: {
      type: Boolean,
      default: false,
    },
    vSessionId: {
      type: String,
      default: '',
    },
    groupedChatHistory: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    QuestionInput,
    Answer,
    ChatHistoryPanel,
  },
  data() {
    return {
      ERROR,
      USER,
      ASSISTANT,
      isLoading: false,
      showLoadingMessage: false,
      messages: [],
      abortFunc: AbortController,
      assistantMessage: {} as ChatMessage,
      toolMessage: {} as ChatMessage,
      assistantContent: '',
      processMessages: '',
    }
  },
  computed: {
    ...mapState('Chat', ['chatHistory', 'currentChat']),
    ...mapGetters('Security', ['userID']),
    questionInputLabel() {
      return this.$t('aiChat.questionInputLabel')
    },
  },
  watch: {
    processMessages(newVal) {
      if (newVal === messageStatus.Done && this.currentChat) {
        this.saveToDB(this.currentChat)
        this.updateChatHistory(this.currentChat)
        this.processMessages = messageStatus.NotRunning
      }
    },
    messages() {
      this.$nextTick(() => {
        this.$refs.chatMessageStreamEnd?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        })
      })
    },
  },
  methods: {
    ...mapActions('Chat', [
      'historyGenerate',
      'updateCurrentChat',
      'historyUpdate',
      'updateChatHistory',
    ]),
    saveToDB({ messages, id }) {
      const payload = {
        conversation_id: id,
        messages: messages,
        config: {
          vSessionId: this.vSessionId,
        },
      }
      this.historyUpdate(payload)
    },
    hideHistory() {
      this.$emit('hideHistory')
    },
    selectHistory(entry) {
      this.messages = [...entry.messages]
    },
    async makeApiRequestWithCosmosDB({ question, conversationId }) {
      this.isLoading = true
      this.showLoadingMessage = true
      this.assistantContent = ''
      this.abortFunc = new AbortController()
      const userMessage: ChatMessage = {
        id: uuid.v1(),
        role: USER,
        content: question,
        date: new Date().toISOString(),
      }

      //api call params set here (generate)
      const currentConversation = this.getConversationById(conversationId)
      const conversationMessages = currentConversation?.messages ?? []

      this.messages = [...conversationMessages, userMessage]

      let result = {} as ChatResponse
      let errorResponseMessage = this.$t('aiChat.errorResponseMessage')

      const payload = {
        conversation_id: conversationId,
        messages: this.messages,
        config: {
          vSessionId: this.vSessionId,
          abortSignal: this.abortFunc.signal,
        },
      }
      try {
        const response = await this.historyGenerate(payload)

        if (!response?.ok) {
          const responseJson = await response.json()
          errorResponseMessage =
            responseJson.error === undefined
              ? errorResponseMessage
              : this.parseErrorMessage(responseJson.error)
          let errorChatMsg: ChatMessage = {
            id: uuid.v1(),
            role: ERROR,
            content: `${this.$t(
              'aiChat.thereAnErrorResponse'
            )} ${errorResponseMessage}`,
            date: new Date().toISOString(),
          }

          if (currentConversation) {
            this.updateCurrentChat({
              ...currentConversation,
              messages: [...this.messages],
            })
          }
          this.messages = [...this.messages, errorChatMsg]
          return
        }

        if (response?.body) {
          const reader = response.body.getReader()

          let runningText = ''
          let done = false
          while (!done) {
            this.processMessages = messageStatus.Processing
            const readerResult = await reader.read()
            done = readerResult.done
            const value = readerResult.value
            if (done) break

            var text = new TextDecoder('utf-8').decode(value)
            const objects = text.split('\n')
            objects.forEach((obj) => {
              try {
                if (obj !== '' && obj !== '{}') {
                  runningText += obj
                  result = JSON.parse(runningText)
                  if (!result.choices?.[0]?.messages?.[0].content) {
                    errorResponseMessage = NO_CONTENT_ERROR
                    throw Error()
                  }
                  if (result.choices?.length > 0) {
                    result.choices[0].messages.forEach((msg) => {
                      msg.id = result.id
                      msg.date = new Date().toISOString()
                    })
                    if (
                      result.choices[0].messages?.some(
                        (m) => m.role === ASSISTANT
                      )
                    ) {
                      this.showLoadingMessage = false
                    }
                    result.choices[0].messages.forEach((resultObj) => {
                      this.processResultMessage(resultObj)
                    })
                  }
                  runningText = ''
                } else if (result.error) {
                  throw Error(result.error)
                }
              } catch (e) {
                if (!(e instanceof SyntaxError)) {
                  console.error(e)
                  throw e
                } else {
                  console.log('Incomplete message. Continuing...')
                }
              }
            })
          }

          if (conversationId && !currentConversation) {
            console.error('Conversation not found.')
            return
          } else {
            let resultConversation = currentConversation ?? {
              id: result.history_metadata.conversation_id,
              title: result.history_metadata.title,
              messages: [],
              date: result.history_metadata.date,
            }

            this.updateCurrentChat({
              ...resultConversation,
              messages: isEmpty(this.toolMessage)
                ? [
                    ...resultConversation.messages,
                    userMessage,
                    this.assistantMessage,
                  ]
                : [
                    ...resultConversation.messages,
                    userMessage,
                    this.toolMessage,
                    this.assistantMessage,
                  ],
            })
          }
        }
      } catch (error) {
        if (!this.abortFunc.signal.aborted) {
          let errorMessage = `An error occurred. ${errorResponseMessage}`
          if (result.error?.message) {
            errorMessage = result.error.message
          } else if (typeof result.error === 'string') {
            errorMessage = result.error
          }

          errorMessage = this.parseErrorMessage(errorMessage)

          let errorChatMsg: ChatMessage = {
            id: uuid.v1(),
            role: ERROR,
            content: errorMessage,
            date: new Date().toISOString(),
          }
          this.messages = [...this.messages, errorChatMsg]
        } else {
          let errorChatMsg: ChatMessage = {
            id: uuid.v1(),
            role: ERROR,
            content: this.$t('aiChat.answersCanNotSaved'),
            date: new Date().toISOString(),
          }
          this.messages = [...this.messages, errorChatMsg]
        }
        this.updateCurrentChat({
          ...currentConversation,
          messages: [...currentConversation.messages, userMessage],
        })
      } finally {
        this.isLoading = false
        this.showLoadingMessage = false
        this.abortFunc = null
        this.processMessages = messageStatus.Done
      }
    },

    getConversationById(conversationId: string) {
      let conversation: Conversation | undefined
      if (conversationId) {
        conversation = this.chatHistory.find(
          (conv: { id: string; messages: ChatMessage[] }) =>
            conv.id === conversationId
        )
      }
      return conversation
    },

    parseCitationFromMessage(message: ChatMessage) {
      if (message?.role && message?.role === 'tool') {
        try {
          const toolMessage = JSON.parse(message.content) as ToolMessageContent
          return toolMessage.citations
        } catch {
          return []
        }
      }
      return []
    },

    stopGenerating() {
      if (this.abortFunc) {
        this.abortFunc.abort()
      }
      this.showLoadingMessage = false
      this.isLoading = false
    },

    tryGetRaiPrettyError(errorMessage: string) {
      try {
        // Using a regex to extract the JSON part that contains "innererror"
        const match = errorMessage.match(/'innererror': ({.*})\}\}/)
        if (match) {
          // Replacing single quotes with double quotes and converting Python-like booleans to JSON booleans
          const fixedJson = match[1]
            .replace(/'/g, '"')
            .replace(/\bTrue\b/g, 'true')
            .replace(/\bFalse\b/g, 'false')
          const innerErrorJson = JSON.parse(fixedJson)
          let reason = ''
          // Check if jailbreak content filter is the reason of the error
          const jailbreak = innerErrorJson.content_filter_result.jailbreak
          if (jailbreak.filtered === true) {
            reason = 'Jailbreak'
          }

          // Returning the prettified error message
          if (reason !== '') {
            return (
              'The prompt was filtered due to triggering Azure OpenAI’s content filtering system.\n' +
              'Reason: This prompt contains content flagged as ' +
              reason +
              '\n\n' +
              'Please modify your prompt and retry. Learn more: https://go.microsoft.com/fwlink/?linkid=2198766'
            )
          }
        }
      } catch (e) {
        console.error('Failed to parse the error:', e)
      }
      return errorMessage
    },

    parseErrorMessage(errorMessage: string) {
      let errorCodeMessage = errorMessage.substring(
        0,
        errorMessage.indexOf('-') + 1
      )
      const innerErrorCue = "{\\'error\\': {\\'message\\': "
      if (errorMessage.includes(innerErrorCue)) {
        try {
          let innerErrorString = errorMessage.substring(
            errorMessage.indexOf(innerErrorCue)
          )
          if (innerErrorString.endsWith("'}}")) {
            innerErrorString = innerErrorString.substring(
              0,
              innerErrorString.length - 3
            )
          }
          innerErrorString = innerErrorString.replace(/\\'/g, "'")
          let newErrorMessage = errorCodeMessage + ' ' + innerErrorString
          errorMessage = newErrorMessage
        } catch (e) {
          console.error('Error parsing inner error message: ', e)
        }
      }

      return this.tryGetRaiPrettyError(errorMessage)
    },

    processResultMessage(resultMessage: ChatMessage) {
      if (resultMessage.role === ASSISTANT) {
        this.assistantContent += resultMessage.content
        this.assistantMessage = resultMessage
        this.assistantMessage.content = this.assistantContent
        const messageId = this.messages.findIndex(
          (msg) => msg.id === resultMessage.id && msg.role === ASSISTANT
        )
        if (messageId !== -1) {
          this.messages[messageId].content = this.assistantContent
        } else {
          this.messages = [...this.messages, resultMessage]
        }
      }

      if (resultMessage.role === TOOL) {
        this.toolMessage = resultMessage
        this.messages = [...this.messages, this.toolMessage]
      }
    },
  },
})
