import { ActionTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'
import repository from '@/repository'
import {
  ChatMessage,
  Conversation,
  Feedback,
  ChatHistoryLoadingState,
} from '@/constants/aiChatModels'

const getDefaultState = (): ChatState => {
  return {
    messages: [],
    chatHistory: [],
    isEnsure: false,
    isChatHistoryOpen: false,
    currentChat: null,
    chatHistoryLoadingState: null,
    feedbackState: {},
  }
}

const state = getDefaultState()

const mutations: MutationTree<ChatState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setMessages(state, value) {
    state.messages = value
  },
  setIsEnsure(state, value) {
    state.isEnsure = value
  },
  toggleChatHistory(state) {
    state.isChatHistoryOpen = !state.isChatHistoryOpen
  },
  updateCurrentChat(state, payload) {
    state.currentChat = payload
  },
  updateChatHistoryLoadingState(state, payload) {
    state.chatHistoryLoadingState = payload
  },
  updateChatHistory(state, payload: Conversation) {
    if (!state.chatHistory || !state.currentChat) {
      return
    }
    const conversationIndex = state.chatHistory.findIndex(
      (conv: Conversation) => conv.id === payload.id
    )
    if (conversationIndex !== -1) {
      const updatedChatHistory = [...state.chatHistory]
      updatedChatHistory[conversationIndex] = state.currentChat
      state.chatHistory = updatedChatHistory
    } else {
      state.chatHistory = [...state.chatHistory, payload]
    }
  },
  updateChatTitle(state, payload) {
    if (!state.chatHistory) {
      state.chatHistory = []
    }
    const updatedChats = state.chatHistory.map((chat) => {
      if (chat.id === payload.id) {
        if (state.currentChat?.id === payload.id) {
          state.currentChat.title = payload.title
        }
        return { ...chat, title: payload.title }
      }
      return chat
    })
    state.chatHistory = updatedChats
  },
  deleteChatEntry(state, payload) {
    if (!state.chatHistory) {
      state.chatHistory = []
    }
    state.chatHistory = state.chatHistory.filter((chat) => chat.id !== payload)
    state.currentChat = null
  },
  deleteChatHistory(state) {
    state.chatHistory = []
    state.currentChat = null
  },
  deleteCurrentChatMessages(state) {
    if (!state.currentChat || !state.chatHistory) {
      return
    }
    state.currentChat = {
      ...state.currentChat,
      messages: [],
    }
  },
  fetchChatHistory(state, payload) {
    state.chatHistory = payload
  },
  setFeedbackState(state, payload) {
    state.feedbackState = {
      ...state.feedbackState,
      [payload.answerId]: payload.feedback,
    }
  },
}

const actions: ActionTree<ChatState, RootState> = {
  async historyUpdate(_, payload) {
    return repository.History.HistoryUpdate(payload, payload.config)
  },
  async historyGenerate(_, payload) {
    return repository.History.HistoryGenerate(payload, payload.config)
  },
  async aiEnsure({ commit }, payload) {
    return repository.History.HistoryEnsure(payload, payload.config)
      .then((res) => {
        if (!res.ok) {
          commit('setIsEnsure', false)
        } else {
          commit('setIsEnsure', true)
        }
      })
      .catch(() => {
        commit('setIsEnsure', false)
      })
  },

  async historyDeleteAll(_, payload) {
    return repository.History.HistoryDeleteAll(payload, payload.config)
  },

  async historyMessageFeedback(_, payload) {
    return repository.History.HistoryMessageFeedback(payload, payload.config)
  },

  async historyList(_, payload) {
    return repository.History.HistoryList(payload, payload.config)
      .then(async (res) => {
        return await res.json()
      })
      .catch(() => {
        return []
      })
  },

  async historyRead(_, payload) {
    return repository.History.HistoryRead(payload, payload.config)
      .then(async (res) => {
        const payload = await res.json()
        const messages: ChatMessage[] = []
        if (payload?.messages) {
          payload.messages.forEach((msg: any) => {
            const message: ChatMessage = {
              id: msg.id,
              role: msg.role,
              date: msg.createdAt,
              content: msg.content,
              feedback: msg.feedback ?? undefined,
            }
            messages.push(message)
          })
        }
        return messages
      })
      .catch(() => {
        return []
      })
  },

  toggleChatHistory({ commit }) {
    commit('toggleChatHistory')
  },
  updateCurrentChat({ commit }, payload) {
    commit('updateCurrentChat', payload)
  },
  updateChatHistoryLoadingState({ commit }, payload) {
    commit('updateChatHistoryLoadingState', payload)
  },
  updateChatHistory({ commit }, payload) {
    commit('updateChatHistory', payload)
  },
  updateChatTitle({ commit }, payload) {
    commit('updateChatTitle', payload)
  },
  deleteChatEntry({ commit }, payload) {
    commit('deleteChatEntry', payload)
  },
  deleteChatHistory({ commit }) {
    commit('deleteChatHistory')
  },
  deleteCurrentChatMessages({ commit }) {
    commit('deleteCurrentChatMessages')
  },
  fetchChatHistory({ commit }, payload) {
    commit('fetchChatHistory', payload)
  },
  setFeedbackState({ commit }, payload) {
    commit('setFeedbackState', payload)
  },
}

const Chat: Module<ChatState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default Chat

interface ChatState {
  messages: ChatMessage[]
  chatHistory: Conversation[] | null
  isEnsure: boolean
  isChatHistoryOpen: boolean
  currentChat: null | Conversation
  chatHistoryLoadingState: null | ChatHistoryLoadingState
  feedbackState: {
    [answerId: string]: Feedback.Neutral | Feedback.Positive | Feedback.Negative
  }
}
