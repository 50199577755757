import store from '@/store'

const vProductIndex = 1

export default {
  History: {
    HistoryUpdate(payload, config) {
      const url =
        store.getters['Environment/assistantSettings'] &&
        store.getters['Environment/assistantSettings'].endpoint
      if (!url) {
        return Promise.reject(new Error('Endpoint URL is not available'))
      }
      return fetch(`${url}/history/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'v-product-index': vProductIndex,
          'v-session-id': config.vSessionId,
        },
        body: JSON.stringify(payload),
      })
    },
    HistoryEnsure(_, config) {
      const url =
        store.getters['Environment/assistantSettings'] &&
        store.getters['Environment/assistantSettings'].endpoint
      if (!url) {
        return Promise.reject(new Error('Endpoint URL is not available'))
      }
      return fetch(`${url}/history/ensure`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'v-product-index': vProductIndex,
          'v-session-id': config.vSessionId,
        },
      })
    },
    HistoryList(payload, config) {
      const url =
        store.getters['Environment/assistantSettings'] &&
        store.getters['Environment/assistantSettings'].endpoint
      if (!url) {
        return Promise.reject(new Error('Endpoint URL is not available'))
      }
      return fetch(`${url}/history/list?offset=${payload.offset}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'v-product-index': vProductIndex,
          'v-session-id': config.vSessionId,
        },
      })
    },
    HistoryRead(payload, config) {
      const url =
        store.getters['Environment/assistantSettings'] &&
        store.getters['Environment/assistantSettings'].endpoint
      if (!url) {
        return Promise.reject(new Error('Endpoint URL is not available'))
      }
      return fetch(`${url}/history/read`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'v-product-index': vProductIndex,
          'v-session-id': config.vSessionId,
        },
        body: JSON.stringify(payload),
      })
    },
    HistoryGenerate(payload, config) {
      const url =
        store.getters['Environment/assistantSettings'] &&
        store.getters['Environment/assistantSettings'].endpoint
      if (!url) {
        return Promise.reject(new Error('Endpoint URL is not available'))
      }
      return fetch(`${url}/history/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'v-product-index': vProductIndex,
          'v-session-id': config.vSessionId,
        },
        body: JSON.stringify(payload),
        signal: config && config.abortSignal,
      })
    },
    HistoryDelete(payload, config) {
      const url =
        store.getters['Environment/assistantSettings'] &&
        store.getters['Environment/assistantSettings'].endpoint
      if (!url) {
        return Promise.reject(new Error('Endpoint URL is not available'))
      }
      return fetch(`${url}/history/delete`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'v-product-index': vProductIndex,
          'v-session-id': config.vSessionId,
        },
        body: JSON.stringify(payload),
      })
    },
    HistoryDeleteAll(payload, config) {
      const url =
        store.getters['Environment/assistantSettings'] &&
        store.getters['Environment/assistantSettings'].endpoint
      if (!url) {
        return Promise.reject(new Error('Endpoint URL is not available'))
      }
      return fetch(`${url}/history/delete_all`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'v-product-index': vProductIndex,
          'v-session-id': config.vSessionId,
        },
        body: JSON.stringify(payload),
      })
    },
    HistoryRename(payload, config) {
      const url =
        store.getters['Environment/assistantSettings'] &&
        store.getters['Environment/assistantSettings'].endpoint
      if (!url) {
        return Promise.reject(new Error('Endpoint URL is not available'))
      }
      return fetch(`${url}/history/rename`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'v-product-index': vProductIndex,
          'v-session-id': config.vSessionId,
        },
        body: JSON.stringify(payload),
      })
    },
    HistoryMessageFeedback(payload, config) {
      const url =
        store.getters['Environment/assistantSettings'] &&
        store.getters['Environment/assistantSettings'].endpoint
      if (!url) {
        return Promise.reject(new Error('Endpoint URL is not available'))
      }
      return fetch(`${url}/history/message_feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'v-product-index': vProductIndex,
          'v-session-id': config.vSessionId,
        },
        body: JSON.stringify(payload),
      })
    },
  },
}
