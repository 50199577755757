
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import ChatHistoryList from './ChatHistoryList.vue'

export default defineComponent({
  name: 'ChatHistoryPanel',
  components: {
    ChatHistoryList,
  },
  props: {
    groupedChatHistory: {
      type: Array,
      default: () => [],
    },
    vSessionId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hideClearAllDialog: false,
      clearing: false,
    }
  },
  computed: {},
  methods: {
    ...mapActions('Chat', ['historyDeleteAll', 'deleteChatHistory']),
    async onClearAllChatHistory() {
      this.clearing = true
      const response = await this.historyDeleteAll({
        config: { vSessionId: this.vSessionId },
      })
      if (response.ok) {
        this.deleteChatHistory()
        this.selectHistory({ messages: [] })
        this.onHideClearAllDialog()
      } else {
        console.error('Error clearing chat history')
      }
      this.clearing = false
    },
    onHideClearAllDialog() {
      this.hideClearAllDialog = false
    },
    toggleClearAllDialog() {
      this.hideClearAllDialog = true
    },
    handleHistoryClick() {
      this.$emit('hideHistory')
    },
    selectHistory(entry: any) {
      this.$emit('selectHistory', entry)
    },
  },
  mounted() {},
})
