import repository from '@/repository'
import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { RootState } from '../types'
const getDefaultState = (): devicePermissionSetsState => {
  return {
    devicePermissionSetsIsLoading: false,
    devicePermissionSetList: [],
    devicePermissionTypes: [],
    newPermissionIsLoading: false,
    updatePermissionIsLoading: false,
    updatePermissionErrorMessage: '',
    updatePermissionSuccess: true,
    addPermissionErrorMessage: '',
    addPermissionSuccess: true,
    assignedDevicePermissionSetList: [],
    assignedDevicePermissionSetsIsLoading: false,
  }
}

const state = getDefaultState()

const getters: GetterTree<devicePermissionSetsState, RootState> = {
  assignedDevicePermission: (state) => {
    return state.assignedDevicePermissionSetList.reduce((acc, item) => {
      if (item.permissionSetId) {
        const findPermission = acc.find(
          (d) => d.permissionSetId === item.permissionSetId
        )
        if (findPermission) {
          findPermission.devices.push(item)
        } else {
          acc.push({
            permissionSetId: item.permissionSetId,
            permissionSetName: item.permissionSetName,
            permissionTypes: item.permissionTypes,
            devices: [item],
          })
        }
      }
      return acc
    }, [])
  },

  assignedDeviceGroupedByLocation: (state) => {
    return state.assignedDevicePermissionSetList.reduce((acc, item) => {
      const findPermission = acc.find((d) => d.id === item.locationId)
      if (findPermission) {
        findPermission.children.push({
          id: item.deviceId,
          name: item.deviceName,
        })
      } else {
        acc.push({
          id: item.locationId,
          name: item.locationName,
          children: [{ id: item.deviceId, name: item.deviceName }],
        })
      }
      return acc
    }, [])
  },
}

const mutations: MutationTree<devicePermissionSetsState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setDevicePermissionSetsIsLoading(state, value) {
    state.devicePermissionSetsIsLoading = value
  },
  setDevicePermissionSetList(state, value) {
    state.devicePermissionSetList = value
  },
  setDevicePermissionTypes(state, value) {
    state.devicePermissionTypes = value
  },
  setNewPermissionIsLoading(state, value) {
    state.newPermissionIsLoading = value
  },
  setUpdatePermissionIsLoading(state, value) {
    state.updatePermissionIsLoading = value
  },
  setUpdatePermissionErrorMessage(state, value) {
    state.updatePermissionErrorMessage = value
  },
  setUpdatePermissionSuccess(state, value) {
    state.updatePermissionSuccess = value
  },
  setAddPermissionErrorMessage(state, value) {
    state.addPermissionErrorMessage = value
  },
  setAddPermissionSuccess(state, value) {
    state.addPermissionSuccess = value
  },
  setAssignedDevicePermissionSetList(state, value) {
    state.assignedDevicePermissionSetList = value
  },
  setAssignedDevicePermissionSetsIsLoading(state, value) {
    state.assignedDevicePermissionSetsIsLoading = value
  },
}

const actions: ActionTree<devicePermissionSetsState, RootState> = {
  async getPermissionSets({ commit }, payload) {
    commit('setDevicePermissionSetsIsLoading', true)
    return repository.DevicePermissionSet.GetDevicePermissionSets(payload)
      .then((response) => {
        commit('setDevicePermissionSetList', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        commit('setDevicePermissionSetsIsLoading', false)
      })
  },
  async createPermissionSet({ commit }, payload) {
    commit('setUpdatePermissionIsLoading', true)
    return repository.DevicePermissionSet.AddDevicePermissionSet(payload)
      .then(() => {
        commit('setUpdatePermissionSuccess', true)
      })
      .catch((error) => {
        commit('setUpdatePermissionSuccess', false)
        commit(
          'setUpdatePermissionErrorMessage',
          error.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setUpdatePermissionIsLoading', false)
      })
  },
  async assignDevicePermission({ commit }, payload) {
    commit('setUpdatePermissionIsLoading', true)
    return repository.DevicePermissionSet.AssignDevicePermission(payload)
      .then(() => {
        commit('setUpdatePermissionSuccess', true)
      })
      .catch((error) => {
        commit('setUpdatePermissionSuccess', false)
        commit(
          'setUpdatePermissionErrorMessage',
          error.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setUpdatePermissionIsLoading', false)
      })
  },
  async deleteDevicePermissionSet({ commit }, payload) {
    commit('setUpdatePermissionIsLoading', true)
    commit('setUpdatePermissionErrorMessage', '')
    return repository.DevicePermissionSet.DeleteDevicePermissionSet(payload)
      .then(() => {
        commit('setUpdatePermissionSuccess', true)
      })
      .catch((error) => {
        commit('setUpdatePermissionSuccess', false)
        commit(
          'setUpdatePermissionErrorMessage',
          error.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setUpdatePermissionIsLoading', false)
      })
  },
  async getDevicePermissionTypes({ commit }, payload) {
    return repository.DevicePermissionSet.GetDevicePermissionTypes(payload)
      .then((response) => {
        commit('setDevicePermissionTypes', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {})
  },
  async updateDevicePermissionSet({ commit }, payload) {
    commit('setUpdatePermissionIsLoading', true)
    return repository.DevicePermissionSet.UpdateDevicePermissionSet(payload)
      .then(() => {
        commit('setUpdatePermissionSuccess', true)
      })
      .catch((error) => {
        commit('setUpdatePermissionSuccess', false)
        commit(
          'setUpdatePermissionErrorMessage',
          error.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setUpdatePermissionIsLoading', false)
      })
  },
  clearUpdatePermissionErrorMessage({ commit }) {
    commit('setUpdatePermissionErrorMessage', '')
  },
  async getAssignedDevicePermissionSets({ commit }, payload) {
    commit('setAssignedDevicePermissionSetsIsLoading', true)
    return repository.DevicePermissionSet.GetDevicesForAssigningPermissionSet(
      payload
    )
      .then((response) => {
        commit('setAssignedDevicePermissionSetList', response.data)
      })
      .catch(() => {
        commit('setAssignedDevicePermissionSetList', [])
      })
      .finally(() => {
        commit('setAssignedDevicePermissionSetsIsLoading', false)
      })
  },
}

const DevicePermissionSets: Module<devicePermissionSetsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default DevicePermissionSets

interface devicePermissionSetsState {
  devicePermissionSetsIsLoading: boolean
  devicePermissionTypes: devicePermissionType[]
  devicePermissionSetList: any[]
  newPermissionIsLoading: boolean
  updatePermissionIsLoading: boolean
  updatePermissionErrorMessage: string
  updatePermissionSuccess: boolean
  addPermissionErrorMessage: string
  addPermissionSuccess: boolean
  assignedDevicePermissionSetList: any[]
  assignedDevicePermissionSetsIsLoading: boolean
}

interface devicePermissionType {
  permissionTypeId: number
  permissionType: string
}
