import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import audioWave from './audioWave.vue'
import trimTransparent from './trimTransparent.vue'
import fiveMPIcon from './5mpIcon.vue'
import analyticsIcon from './analyticsIcon.vue'
import vehicleIcon from './vehicleIcon.vue'
import personIcon from './personIcon.vue'
import doorIcon from './doorIcon.vue'
import creditCardIcon from './creditCardIcon.vue'
import dashboardIcon from './dashboardIcon.vue'
import globeIcon from './360Icon.vue'
import alert2Icon from './alert2Icon.vue'
import alert3Icon from './alert3Icon.vue'
import alertIcon from './alertIcon.vue'
import avatar2Icon from './avatar2Icon.vue'
import avatarIcon from './avatarIcon.vue'
import athleticsIcon from './athletics.vue'
import backArrow from './backArrow.vue'
import backwardIcon from './backwardIcon.vue'
import bellIcon from './bellIcon.vue'
import bookmark from './bookmark.vue'
import building from './building.vue'
import calendarIcon from './calendarIcon.vue'
import cameraIcon from './cameraIcon.vue'
import checkedIcon from './checkedIcon.vue'
import circleQuestion from './circleQuestion.vue'
import clockIcon from './clockIcon.vue'
import cloudStorageIcon from './cloudStorageIcon.vue'
import customerLocation from './customerLocationIcon.vue'
import customerManagementIcon from './customerManagementIcon.vue'
import dealerManagement from './dealerManagementIcon.vue'
import downCaret from './downCaret.vue'
import emptyDevice from './emptyDevice.vue'
import emptyLocation from './emptyLocation.vue'
import exportIcon from './exportIcon.vue'
import forwardIcon from './forwardIcon.vue'
import fullScreenIcon from './fullScreenIcon.vue'
import gearIcon from './gearIcon.vue'
import gridIcon from './gridIcon.vue'
import helpIcon from './helpIcon.vue'
import historyIcon from './historyIcon.vue'
import indeterminate from './indeterminateIcon.vue'
import infoIcon from './infoIcon.vue'
import leftCaret from './leftCaret.vue'
import libraryIcon from './libraryIcon.vue'
import listIcon from './listIcon.vue'
import loadingIcon from './loadingIcon.vue'
import locationIcon from './locationIcon.vue'
import loginLogo from './loginLogo.vue'
import logoIcon from './logoIcon.vue'
import logout from './logout.vue'
import lockIcon from './lockIcon.vue'
import mailIcon from './mailIcon.vue'
import messagesIcon from './messagesIcon.vue'
import next from './next.vue'
import offlineCameraIcon from './offlineCameraIcon.vue'
import pauseIcon from './pauseIcon.vue'
import playIcon from './playIcon.vue'
import previous from './previous.vue'
import questionCircleIcon from './questionCircleIcon.vue'
import redoIcon from './redoIcon.vue'
import rightCaret from './rightCaret.vue'
import roleIcon from './roleIcon.vue'
import scissorsIcon from './scissorsIcon.vue'
import screenshotIcon from './screenshotIcon.vue'
import sdCardIcon from './sdCardIcon.vue'
import selectionIcon from './selectionIcon.vue'
import settingsIcon from './settingsIcon.vue'
import singleCameraIcon from './singleCameraIcon.vue'
import smartSearchIcon from './smartSearchIcon.vue'
import storage from './storage.vue'
import successIcon from './successIcon.vue'
import teamIcon from './teamIcon.vue'
import tokenIcon from './tokenIcon.vue'
import trashCanIcon from './trashCanIcon.vue'
import trim from './trim.vue'
import trimRedIcon from './trim-red.vue'
import uncheckedIcon from './uncheckedIcon.vue'
import upCaret from './upCaret.vue'
import userIcon from './userIcon.vue'
import walkIcon from './walkIcon.vue'
import zoomInIcon from './zoomInIcon.vue'
import zoomOutIcon from './zoomOutIcon.vue'
import nvrIcon from './nvrIcon.vue'
import forwardNoText from './forwardNoText.vue'
import backwardNoText from './backwardNoText.vue'
import view360degIcon from './view360degIcon.vue'
import viewIcon from './viewIcon.vue'
import addAARuleIcon from './addAARuleIcon.vue'
import devicePermissionIcon from './devicePermissionIcon.vue'

import fr from 'vuetify/lib/locale/fr'
import es from 'vuetify/lib/locale/es'
import en from 'vuetify/lib/locale/en'
Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    lang: {
      locales: { en, fr, es },
      current: 'en',
    },
    dark: true,
    themes: {
      dark: {
        primary: '#FFFFFF',
        gray: '#272727',
        secondary: '#E20039',
        yellow: '#FFB300',
        darkBackground: '#1a1a1a',
        veryDarkBackground: '#121212',
        green: '#00D592',
        greenBackground: '#134837',
        lightGray: '#535353',
        blue: '#0091FF',
        lightBlack: '#1e1e1e',
        containerGray: '#2a2a2a',
      },
    },
    options: { variations: false },
  },
  icons: {
    iconfont: 'mdiSvg',
    values: {
      analyticsIcon: {
        component: analyticsIcon,
      },
      vehicleIcon: {
        component: vehicleIcon,
      },
      personIcon: {
        component: personIcon,
      },
      doorIcon: {
        component: doorIcon,
      },
      fiveMPIcon: {
        component: fiveMPIcon,
      },
      dashboard: {
        component: dashboardIcon,
      },
      creditCardIcon: {
        component: creditCardIcon,
      },
      globeIcon: {
        component: globeIcon,
      },
      helpIcon: {
        component: helpIcon,
      },
      forwardNoText: {
        component: forwardNoText,
      },
      backwardNoText: {
        component: backwardNoText,
      },
      circleQuestion: {
        component: circleQuestion,
      },
      location: {
        component: locationIcon,
      },
      trim: {
        component: trim,
      },
      trimRed: {
        component: trimRedIcon,
      },
      trimTransparent: {
        component: trimTransparent,
      },
      audioWave: {
        component: audioWave,
      },
      grid: {
        component: gridIcon,
      },
      list: {
        component: listIcon,
      },
      bell: {
        component: bellIcon,
      },
      gear: {
        component: gearIcon,
      },
      avatar: {
        component: avatarIcon,
      },
      avatar2: {
        component: avatar2Icon,
      },
      athletics: {
        component: athleticsIcon,
      },
      calendar: {
        component: calendarIcon,
      },
      clock: {
        component: clockIcon,
      },
      pause: {
        component: pauseIcon,
      },
      logout: {
        component: logout,
      },
      lock: {
        component: lockIcon,
      },
      forward: {
        component: forwardIcon,
      },
      backward: {
        component: backwardIcon,
      },
      zoomIn: {
        component: zoomInIcon,
      },
      zoomOut: {
        component: zoomOutIcon,
      },
      logo: {
        component: logoIcon,
      },
      play: {
        component: playIcon,
      },
      leftCaret: {
        component: leftCaret,
      },
      rightCaret: {
        component: rightCaret,
      },
      upCaret: {
        component: upCaret,
      },
      downCaret: {
        component: downCaret,
      },
      loginLogo: {
        component: loginLogo,
      },
      backArrow: {
        component: backArrow,
      },
      cameraIcon: {
        component: cameraIcon,
      },
      bookmark: {
        component: bookmark,
      },
      scissors: {
        component: scissorsIcon,
      },
      user: {
        component: userIcon,
      },
      messages: {
        component: messagesIcon,
      },
      unchecked: {
        component: uncheckedIcon,
      },
      checkboxOff: {
        component: uncheckedIcon,
      },
      checked: {
        component: checkedIcon,
      },
      checkboxOn: {
        component: checkedIcon,
      },
      indeterminateIcon: {
        component: indeterminate,
      },
      info: {
        component: infoIcon,
      },
      library: {
        component: libraryIcon,
      },
      history: {
        component: historyIcon,
      },
      offlineCamera: {
        component: offlineCameraIcon,
      },
      customerManagementIcon: {
        component: customerManagementIcon,
      },
      singleCamera: {
        component: singleCameraIcon,
      },
      building: {
        component: building,
      },
      customerLocation: {
        component: customerLocation,
      },
      emptyLocation: {
        component: emptyLocation,
      },
      exportIcon: {
        component: exportIcon,
      },
      emptyDevice: {
        component: emptyDevice,
      },
      teamIcon: {
        component: teamIcon,
      },
      tokenIcon: {
        component: tokenIcon,
      },
      roleIcon: {
        component: roleIcon,
      },
      alert: {
        component: alertIcon,
      },
      nextIcon: {
        component: next,
      },
      previousIcon: {
        component: previous,
      },
      dealerManagementIcon: {
        component: dealerManagement,
      },
      settingsIcon: {
        component: settingsIcon,
      },
      loadingIcon: {
        component: loadingIcon,
      },
      redoIcon: {
        component: redoIcon,
      },
      successIcon: {
        component: successIcon,
      },
      alert2Icon: {
        component: alert2Icon,
      },
      alert3Icon: {
        component: alert3Icon,
      },
      sdCardIcon: {
        component: sdCardIcon,
      },
      smartSearchIcon: {
        component: smartSearchIcon,
      },
      selectionIcon: {
        component: selectionIcon,
      },
      cloudStorageIcon: {
        component: cloudStorageIcon,
      },
      screenshotIcon: {
        component: screenshotIcon,
      },
      fullScreenIcon: {
        component: fullScreenIcon,
      },
      storageIcon: {
        component: storage,
      },
      mailIcon: {
        component: mailIcon,
      },
      questionCircleIcon: {
        component: questionCircleIcon,
      },
      trashCan: {
        component: trashCanIcon,
      },
      walkIcon: {
        component: walkIcon,
      },
      nvrIcon: {
        component: nvrIcon,
      },
      view360degIcon: {
        component: view360degIcon,
      },
      viewIcon: {
        component: viewIcon,
      },
      addAARuleIcon: {
        component: addAARuleIcon,
      },
      devicePermissionIcon: {
        component: devicePermissionIcon,
      },
    },
  },
})
