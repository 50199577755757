
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
export default defineComponent({
  name: 'ChatHistoryList',
  components: {},
  props: {
    groupedChatHistory: {
      type: Array,
      default: () => [] as any[],
      required: true,
    },
  },
  data() {
    return {
      showSpinner: false,
    }
  },
  computed: {},
  methods: {
    ...mapActions('Chat', ['updateCurrentChat']),
    handleSelectHistory(entry: any) {
      this.updateCurrentChat(entry)
      this.$emit('selectHistory', entry)
    },
    formatMonth(month: string) {
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()

      const [monthName, yearString] = month.split(' ')
      const year = parseInt(yearString)

      if (year === currentYear) {
        return monthName
      } else {
        return month
      }
    },
  },
  mounted() {},
})
