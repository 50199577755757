import httpClient from './httpClient'

import auditEndpoints from './auditEndpoints'
import caseArtifactEndpoints from './caseArtifactEndpoints'
import caseEndpoints from './caseEndpoints'
import commentEndpoints from './commentEndpoints'
import countryCodeEndpoints from './countryCodeEndpoints'
import customerEndpoints from './customerEndpoints'
import dealerEndpoints from './dealerEndpoints'
import deviceEndpoints from './deviceEndpoints'
import externalEndpoints from './externalEndpoints'
import internalAdminEndpoints from './internalAdminEndpoints'
import locationEndpoints from './locationEndpoints'
import multiViewEndpoints from './multiViewEndpoints'
import notificationEndpoints from './notificationEndpoints'
import onboardingEndpoints from './onboardingEndpoints'
import recentlyViewedEndpoints from './recentlyViewedEndpoints'
import roleEndpoints from './roleEndpoints'
import scheduleEndpoints from './scheduleEndpoints'
import thumbnailEndpoints from './thumbnailEndpoints'
import timelineEndpoints from './timelineEndpoints'
import timezoneEndpoints from './timezoneEndpoints'
import userEndpoints from './userEndpoints'
import videoEndpoints from './videoEndpoints'
import tokenEndpoints from './tokenEndpoints'
import device from '@/mixins/device'
import devicePermissionEndpoints from './devicePermissionEndpoints'
import historyEndpoints from './historyEndpoints'

export default {
  ...auditEndpoints,
  ...caseArtifactEndpoints,
  ...caseEndpoints,
  ...commentEndpoints,
  ...countryCodeEndpoints,
  ...customerEndpoints,
  ...dealerEndpoints,
  ...deviceEndpoints,
  ...externalEndpoints,
  ...internalAdminEndpoints,
  ...locationEndpoints,
  ...multiViewEndpoints,
  ...notificationEndpoints,
  ...onboardingEndpoints,
  ...recentlyViewedEndpoints,
  ...roleEndpoints,
  ...scheduleEndpoints,
  ...thumbnailEndpoints,
  ...timelineEndpoints,
  ...timezoneEndpoints,
  ...userEndpoints,
  ...videoEndpoints,
  ...tokenEndpoints,
  ...devicePermissionEndpoints,
  ...historyEndpoints,
  Status: {
    GetAPIStatus() {
      return httpClient.get('/Status')
    },
  },
  Environment: {
    GetSettings() {
      return httpClient.get('/Environment')
    },
  },
  Test: {
    GetInstanceName() {
      return httpClient.get('/Test/GetInstanceName')
    },
  },
}
